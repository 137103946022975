import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { ENV_CONFIG } from 'config/environment';
import { defaultOptions, LottieProps } from './commonOptions';
import { ChildrenContainer, OrbsContainer } from './styled';

const LottieOrbs: FC<LottieProps> = ({ children }) => {
  const [lottieStyle, setLottieStyle] = useState({
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 0,
  });

  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Fetch the JSON file from the URL
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(`${ENV_CONFIG().CLOUD_STORAGE.URL}/json/blueOrbs.json`);
        const jsonData = await response.json();
        setAnimationData(jsonData);
      } catch (error) {
        console.error('Error loading JSON:', error);
      }
    };

    fetchAnimationData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setLottieStyle({
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20, // Add left radius when width <= 1000px
        });
      } else {
        setLottieStyle({
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 0, // Remove left radius for larger widths
        });
      }
    };

    handleResize(); // Run on mount
    window.addEventListener('resize', handleResize); // Listen to window resize
    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  return (
    <OrbsContainer>
      {animationData && (
        <Lottie
          options={defaultOptions(animationData)}
          style={lottieStyle}
          isClickToPauseDisabled={true}
        />
      )}
      <ChildrenContainer>{children}</ChildrenContainer>
    </OrbsContainer>
  );
};

export default LottieOrbs;
