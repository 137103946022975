import { FC, Fragment, useEffect, useState } from 'react';
import { animate, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import LottieStory from 'components/LottieElements/Story';
import { ISlideProps } from '.';
import { DURATION_1, DURATION_2, OPACITY_0, OPACITY_1 } from './animations';
import { Container, MotionImage, MotionText } from './styled';

export const Slide3: FC<ISlideProps> = ({ isPaused, impactReportData }) => {
  const [showFirstLayer, setShowFirstLayer] = useState(true);
  const [showSecondLayer, setShowSecondLayer] = useState(false);

  const timerFirstLayer = useMotionValue(0);
  useTransform(timerFirstLayer, latest => Math.round(latest));

  useEffect(() => {
    if (showFirstLayer) {
      const controls = animate(timerFirstLayer, 5, {
        duration: 5,
      });
      if (isPaused) controls.pause();
    }
  }, [showFirstLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = timerFirstLayer.on('change', latest => {
      if (Math.round(latest) >= 5 && !isPaused && impactReportData.issues.length > 1) {
        setTimeout(() => {
          setShowFirstLayer(false);
          setTimeout(() => {
            setShowSecondLayer(true);
          }, 1000);
        }, 4000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [timerFirstLayer, isPaused]);

  return (
    <>
      {showFirstLayer ? (
        <LottieStory slide={`tag_${impactReportData.issues[0].tag_id}`} isPaused={isPaused}>
          <AnimatePresence>
            {showFirstLayer && (
              <Container
                key="firstLayer"
                initial={OPACITY_0}
                animate={OPACITY_1}
                exit={OPACITY_0}
                transition={DURATION_2}
              >
                <MotionText
                  $size={16}
                  initial={{ ...OPACITY_0, position: 'absolute', top: 30 }}
                  animate={OPACITY_1}
                  transition={DURATION_1}
                >
                  Your Top Issue
                </MotionText>
                <MotionImage
                  $isTop
                  key={impactReportData.issues[0].tag_id}
                  src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/test_tags/tag_${
                    impactReportData.issues[0].tag_id
                  }.png`}
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 1 }}
                />
                <Spacing $size={20} />
                <MotionText
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 1 }}
                >
                  {impactReportData.issues[0].tag_name}
                </MotionText>
                <Spacing $size={20} />
                <MotionText
                  $size={16}
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 2 }}
                >
                  More than {impactReportData.top_issue_donation_percentage}% of your donations went
                  to candidates fighting against {impactReportData.issues[0].tag_name}.
                </MotionText>
              </Container>
            )}
          </AnimatePresence>
        </LottieStory>
      ) : (
        <LottieStory slide="blueOrbs" isPaused={isPaused}>
          <AnimatePresence>
            {showSecondLayer && (
              <Container
                key="secondLayer"
                initial={OPACITY_0}
                animate={OPACITY_1}
                transition={DURATION_1}
              >
                <MotionText
                  $size={16}
                  initial={{ ...OPACITY_0, position: 'absolute', top: 30 }}
                  animate={OPACITY_1}
                  transition={DURATION_1}
                >
                  Your Top {impactReportData.issues.length} Issues
                </MotionText>
                {impactReportData.issues.map((issue, index) => (
                  <Fragment key={index}>
                    <Row $alignItems="center" $justifySelf="flex-start">
                      <MotionImage
                        key={impactReportData.issues[0].tag_id}
                        src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/test_tags/tag_${issue.tag_id}.png`}
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={{ ...DURATION_1, delay: 0.25 * (index + 1) }}
                      />
                      <Spacing $isVertical $size={16} />
                      <MotionText
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={{
                          ...DURATION_1,
                          delay: impactReportData.issues.length * 0.25 + 0.25 * (index + 1),
                        }}
                      >
                        {index + 1}. {issue.tag_name}
                      </MotionText>
                    </Row>
                    <Spacing $size={16} />
                  </Fragment>
                ))}
              </Container>
            )}
          </AnimatePresence>
        </LottieStory>
      )}
    </>
  );
};
