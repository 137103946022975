import { Link, useNavigate } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Button from 'components/Button';
import Banner from 'components/Banner';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { Link as CustomLink } from 'components/Link';
import { FooterDivider } from 'components/Footer/styled';
import { ActionListItem } from 'components/ActionListItem';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getPathWithRef, getUrlParams } from 'helpers/utils';
import { BANNER, getIsFeatureOn } from 'helpers/featureFlags';
import { getSessionID, getSiteAccessToken, getUserName, setRefValue } from 'services/storage';
import {
  BarContainer,
  HeaderName,
  MotionButton,
  MotionDiv,
  RowHeader,
  StyledImage,
  RowLoginLargeOnly,
  RowLoginSmallOnly,
  InfoText,
  HeaderItemsContainer,
} from './styled';

export const Bar = () => {
  const navigate = useNavigate();
  const { urlRef } = getUrlParams();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const isMainScreen = window.location.pathname === '/';

  const sidebar = {
    open: () => ({
      clipPath: 'inset(0 0 0% 0%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    }),
    closed: {
      clipPath: 'inset(0 100% 0% 0%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const loginClick = () => {
    if (urlRef) setRefValue(urlRef);
    window.open(`${ENV_CONFIG().SITE_URL}/login`, '_blank');
  };

  const getLoggedInDisplayText = () => {
    const userName = getUserName();
    return userName && userName !== 'null' ? `Hi, ${userName}` : 'My Account';
  };

  const handleLinkPressed = (path: string) => {
    const linksMap = {
      '/donor': 'donor',
      '/donate': 'donate',
      '/set?p=top-issues': 'top_issue',
    };
    logFirebaseEvent(`header_${linksMap[path]}`, {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
    });
    navigate(getPathWithRef(path));
    navigate(0);
  };

  return (
    <>
      {getIsFeatureOn(BANNER) && (
        <Banner
          text={
            <div>
              3 Days until election. direct Your Funds to these Candidates{' '}
              <CustomLink $fontType="semiBold" $hoverColor={theme.colors.oathBlue}>
                HERE
              </CustomLink>
              .
            </div>
          }
        />
      )}
      <BarContainer $isOpen={isOpen}>
        <RowHeader $justifyContent="space-between">
          <Row $alignItems="center">
            <MotionButton whileTap={{ scale: 0.9 }} $isFromSidebar>
              <Icon
                onClick={toggleOpen}
                name={isOpen ? IconNames.XClose : IconNames.Menu}
                size={32}
                color={theme.colors.inkBlue}
                style={{ padding: 16 }}
              />
            </MotionButton>
            <StyledImage
              alt="Oath logo"
              onClick={() => navigate(getPathWithRef('/'))}
              src="https://storage.googleapis.com/oath_1/static_content/oath_logos/Oath_FullLockup_Color_DarkText.svg"
            />
            <HeaderItemsContainer>
              <InfoText onClick={() => handleLinkPressed('/donor')}>About Oath</InfoText>
              <Spacing $isVertical $size={40} />
              <InfoText onClick={() => handleLinkPressed('/donate')}>Search Candidates</InfoText>
              <Spacing $isVertical $size={40} />
              <InfoText onClick={() => handleLinkPressed('/set?p=top-issues')}>Top Issues</InfoText>
            </HeaderItemsContainer>
          </Row>
          <Row>
            {getSiteAccessToken() ? (
              <Row>
                <Icon
                  name={IconNames.User}
                  size={32}
                  color={theme.colors.black}
                  style={{ padding: '16px 5px' }}
                />
                <HeaderName $isCentered>
                  <Link to="/profile" style={{ color: 'inherit', textDecoration: 'none' }}>
                    {getLoggedInDisplayText()}
                  </Link>
                </HeaderName>
              </Row>
            ) : (
              <>
                <RowLoginLargeOnly>
                  <Row $pressable onClick={loginClick}>
                    <Icon
                      name={IconNames.User}
                      size={32}
                      color={theme.colors.black}
                      style={{ padding: '16px 5px' }}
                    />
                    <HeaderName $isCentered>Log In</HeaderName>
                  </Row>
                  <Button
                    text="JOIN OATH"
                    onPress={loginClick}
                    backgroundColor={theme.colors.oathBlue}
                  />
                </RowLoginLargeOnly>
                <RowLoginSmallOnly>
                  <Button
                    text="LOG IN"
                    onPress={loginClick}
                    backgroundColor={theme.colors.oathBlue}
                  />
                </RowLoginSmallOnly>
              </>
            )}
          </Row>
        </RowHeader>
        <FooterDivider />
        <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'}>
          <MotionDiv variants={sidebar}>
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/'))}
              label="Home"
              selected={isMainScreen}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/donor'))}
              label="About Oath"
              selected={window.location.href.includes('donor')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/donate'))}
              label="Search Candidates"
              selected={window.location.href.includes('donate')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/set?p=top-issues'))}
              label="Top Issues"
              selected={window.location.href.includes('set?p=top-issues')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/monthly'))}
              label="Donate Monthly"
              selected={window.location.href.includes('monthly')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/impact_score'))}
              label="Our Algorithm"
              selected={window.location.href.includes('impact_score')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/our_team'))}
              label="Our Team"
              selected={window.location.href.includes('our_team')}
            />
            <ActionListItem
              onclick={() => navigate(getPathWithRef('/contact'))}
              label="Contact us"
              lastone
              selected={window.location.href.includes('contact')}
            />
          </MotionDiv>
        </motion.nav>
      </BarContainer>
    </>
  );
};
