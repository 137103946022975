import { Body, Eyebrow, H4 } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import theme from 'config/theme';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import Spacing from 'components/Spacing';
import { useEffect, useState } from 'react';
import { getFundingGapAPI } from 'store/funding_gap/actions';
import { FundingGap } from 'store/funding_gap/types';
import { CardPadding, CountdownHR, CountdownLinkRow } from './styled';
import { Counter } from './Counter';
import { noop } from 'helpers/utils';

export const DollarCountCard = () => {
  const [fundingGap, setFundingGap] = useState<FundingGap | undefined>();

  useEffect(() => {
    getFundingGapAPI()
      .then(response => {
        setFundingGap(response.data);
      })
      .catch(() => {
        noop();
      });
  }, []);

  return (
    <GradientBorderCard borderType="top" gradient={theme.gradients.electricToDarkGradient({})}>
      <Spacing $size={17} />
      <CardPadding>
        <Eyebrow $color={theme.colors.inkBlue}>Join the blue wave</Eyebrow>
        {!!fundingGap?.current_gap_amount_estimate && (
          <>
            <Spacing $size={17} />
            <Counter number={fundingGap.current_gap_amount_estimate} />
            <Spacing $size={17} />
            <H4>is needed to close the gap for key downballot races</H4>
          </>
        )}
      </CardPadding>
      <CountdownHR />
      <CardPadding>
        <Body>
          Harris-Walz have already raised a historic $600+ million, while Democrats downballot are
          being outraised in races that cost <b>as little as $50K</b>. Make your dollar go further
          by supporting these competitive downballot races.
        </Body>
        <Spacing $size={17} />
        <CountdownLinkRow $justifyContent="space-between">
          <ButtonBrand to="/set?p=top-issues" $backgroundColor={theme.colors.oathBlue}>
            Support by Issue
          </ButtonBrand>
          <ButtonBrand to="/set?p=states" $backgroundColor={theme.colors.oathBlue}>
            Support by State
          </ButtonBrand>
        </CountdownLinkRow>
        {/* <Spacing $size={10} />
        <CountdownLinkRow $justifyContent="space-between">
          <UtilityText $color={theme.shadows.black(0.6)}>Updated at 12:05pm</UtilityText>
          <UtilityText $color={theme.shadows.black(0.6)}>$4.5 million raised</UtilityText>
        </CountdownLinkRow> */}
      </CardPadding>
      <Spacing $size={17} />
    </GradientBorderCard>
  );
};
