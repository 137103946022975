import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import { Link } from 'components/Link';
import { Row } from 'components/Row';
import { formatNumber, formatNumberAsCurrency, scrollToTop } from 'helpers/utils';
import { Body, BodySmall, H4, UtilityText } from 'components/Typography';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { PaymentsData } from 'store/payments/types';
import { checkoutLogFirebaseEvent, getIsCheckoutMonthly, usePaymentOptions } from './utils';
import { Tip } from './Tip';
import { useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { PaymentMethod } from 'store/pay/types';
import { getIsInStop2End, getIsPageStop2End } from 'helpers/stop2end';
import { Stop2EndDisclaimer } from '../styled';

const getPaymentDisplay = (
  paymentType: PaymentMethod,
  token: string,
  paymentOptions: PaymentOptions,
  ccNumber?: string,
  achAccountNumber?: string
) => {
  const paymentTypeDisplay = paymentType === 'ach' ? 'Bank Account' : 'Credit Card';
  const paymentOptionKey = paymentType === 'ach' ? 'achPayments' : 'ccPayments';
  const selectedPayment = paymentOptions[paymentOptionKey].find(
    payment => payment.payment_authorization_token === token
  );
  const savedPaymentLast4 =
    selectedPayment && selectedPayment.payment_authorization_display_name.slice(-4);
  let newPaymentLast4 = '';
  if (paymentType === 'credit_card' && ccNumber) {
    newPaymentLast4 = ccNumber.slice(-4);
  } else if (achAccountNumber) {
    newPaymentLast4 = achAccountNumber.slice(-4);
  }

  return `${paymentTypeDisplay} ${savedPaymentLast4 || newPaymentLast4}`;
};

interface PaymentOptions {
  achPayments: PaymentsData[];
  ccPayments: PaymentsData[];
}
interface Props {
  backToDonatePath: string;
  candidates: CandidateType[];
  donationAmount: number;
  onBackToDonatePage: () => void;
  numCandidates: number;
  setActivePageIndex: Dispatch<SetStateAction<number>>;
}

export const Review: FC<Props> = ({
  backToDonatePath,
  candidates,
  donationAmount,
  onBackToDonatePage,
  numCandidates,
  setActivePageIndex,
}) => {
  const form = useForm();
  const {
    ach_account_number: achAccountNumber,
    cc_number: ccNumber,
    default_token: paymentToken,
    email,
    first_name: firstName,
    last_name: lastName,
    payment_type: paymentType,
  } = form.getState().values;
  const {
    input: { value: tip },
  } = useField('tip', { subscription: { value: true } });
  const paymentOptions = usePaymentOptions();
  const userState = useAppSelector(getUserState);

  useEffect(() => {
    scrollToTop();
    checkoutLogFirebaseEvent({
      eventName: 'view_step_review',
      userId: userState?.data?.id || '',
      candidates,
      donationAmount,
    });
  }, []);

  return (
    <>
      <Row $justifyContent="space-between">
        <Body>Contribution Summary</Body>
        <Link to={backToDonatePath} onClick={onBackToDonatePage} $color={theme.shadows.black(0.5)}>
          <BodySmall $color="inherit">Edit</BodySmall>
        </Link>
      </Row>
      <Spacing $size={4} />
      <Row $justifyContent="space-between">
        <div>
          <div>
            <H4>{formatNumberAsCurrency(Number(donationAmount) + Number(tip))}</H4>
          </div>
          <Spacing $size={2} />
          <div>
            <UtilityText>
              {getIsCheckoutMonthly() ? 'Recurring monthly contribution' : 'One-time contribution'}
            </UtilityText>
          </div>
          <div>
            <UtilityText>Including {formatNumberAsCurrency(tip)} tip to Oath</UtilityText>
          </div>
        </div>
        <div>
          <div>
            <H4>
              {formatNumber(numCandidates)} recipient{numCandidates !== 1 && 's'}
            </H4>
          </div>
        </div>
      </Row>
      <Spacing $size={13} />
      <div>
        <Row $justifyContent="space-between">
          <Body>Personal Details</Body>
          <Link
            onClick={() => setActivePageIndex(0)}
            $color={theme.shadows.black(0.5)}
            type="button"
          >
            <BodySmall $color="inherit">Edit</BodySmall>
          </Link>
        </Row>
        <Spacing $size={2} />
        <div>
          <BodySmall>{`${firstName} ${lastName}`}</BodySmall>
        </div>
        <div>
          <BodySmall>{email}</BodySmall>
        </div>
      </div>
      <Spacing $size={13} />
      <div>
        <Row $justifyContent="space-between">
          <Body>Payment Method</Body>
          <Link
            onClick={() => setActivePageIndex(1)}
            $color={theme.shadows.black(0.5)}
            type="button"
          >
            <BodySmall $color="inherit">Edit</BodySmall>
          </Link>
        </Row>
        <Spacing $size={2} />
        <BodySmall>
          {getPaymentDisplay(paymentType, paymentToken, paymentOptions, ccNumber, achAccountNumber)}
        </BodySmall>
      </div>
      <Spacing $size={13} />
      <div>
        <div>
          <Body>Oath is powered by donor tips</Body>
        </div>
        <Spacing $size={2} />
        <div>
          <BodySmall>
            Oath never takes a cut or sells your data. We rely on support from donors like you to
            provide our expert analysis.
          </BodySmall>
          <Tip donationAmount={donationAmount} tipAmount={tip} />
        </div>
      </div>
      <Spacing $size={33} />
      {getIsInStop2End(email, userState?.data?.id) && (
        <Stop2EndDisclaimer>
          <UtilityText>
            By clicking below, you authorize 51C Collaborative, Inc. (DBA "Oath") to unsubscribe
            your logged in email address from unwanted political campaign and committee
            communications on your behalf.
          </UtilityText>
        </Stop2EndDisclaimer>
      )}
      {getIsPageStop2End() && !getIsInStop2End(email, userState?.data?.id) && (
        <Stop2EndDisclaimer>
          <UtilityText>
            WARNING: You are not logged in. If you are intending to opt-in for the STOP2END campaign
            and have Oath help unsubscribe you from political lists, please go back to Step 2 and
            click "Sign in to give faster".
          </UtilityText>
        </Stop2EndDisclaimer>
      )}
    </>
  );
};
