import { Image } from 'components/Image';
import { Row } from 'components/Row';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const Title = styled.span<{ $size?: number }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ $size }) => ($size ? `${$size}px` : '115px')};
  letter-spacing: -0.01em;
  line-height: ${({ $size }) => ($size ? `${$size}px` : '115px')};
  ${({ theme }) => theme.fonts.panelSansMedium};
`;

export const StyledLogo = styled(Image)`
  height: 20px;
`;

export const StyledImage = styled(Image)`
  height: 40px;
  position: absolute;
  bottom: 64px;
`;

export const MotionContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const SquareContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.inkBlue};
  display: grid;
  justify-items: center;
  padding: 26px;
  position: absolute;
  width: -webkit-fill-available;
  right: 30px;
  left: 30px;
`;

export const OuterContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.oathBlue};
  padding: 0 30px;
`;

export const Container = styled(motion.div)<{ $alignContent?: string }>`
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-items: center;
  align-content: ${({ $alignContent }) => $alignContent || 'center'};
  text-align: center;
  display: grid;
`;

export const MotionImage = styled(motion.img)<{ $isTop?: boolean }>`
  max-width: ${({ $isTop }) => ($isTop ? '300px' : '75px')};
  max-height: ${({ $isTop }) => ($isTop ? '300px' : '75px')};
`;

export const MotionCandidateImage = styled(motion.img)<{ $isTop?: boolean }>`
  width: 45px;
  height: 45px;
  border-radius: 100px;
`;

export const MotionText = styled(motion.span)<{ $size?: number; $italic?: boolean }>`
  font-size: ${({ $size }) => ($size ? `${$size}px` : '22px')};
  letter-spacing: -0.01em;
  ${({ theme, $italic }) => ($italic ? theme.fonts.semiBoldItalic : theme.fonts.regular)};
  color: ${({ theme }) => theme.colors.white};
`;

export const BigCounter = styled(motion.span)<{ $size?: number }>`
  font-size: ${({ $size }) => ($size ? `${$size}px` : '120px')};
  line-height: 80px;
  letter-spacing: -0.01em;
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.white};
`;

export const CandidatesRow = styled(Row)`
  flex-wrap: wrap;
  gap: 15px 25px;
`;
