import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { ENV_CONFIG } from 'config/environment';
import { CustomSlider } from 'components/Slider';
import LottieOrbs from 'components/LottieElements/Orbs';
import { ButtonBrand } from 'components/ButtonBrand';
import { BodySmall, Eyebrow, H4 } from 'components/Typography';
import { useAppSelector } from 'helpers/hooks';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getIsFeatureOn, IMPACT_REPORT } from 'helpers/featureFlags';
import { formatNumberAsCurrency, getUrlParams, isKeyInObject } from 'helpers/utils';
import { tabIssues } from 'screens/Monthly/constants';
import { getUserData } from 'selectors/user';
import { getTransactionsSummary } from 'selectors/transactions';
import { getRecommendationsData } from 'selectors/recommendations';
import { BigText, RowContainer, LeftContent, RightContent, SmallText } from './styled';

const DEFAULT_RECOMMENDATION_BUDGET = 50;

export const Impact = ({ viewImpactReport }) => {
  const { urlRef } = getUrlParams();
  const ref_code = urlRef ? `&ref=${urlRef}-PRD` : '&ref=PRD';
  const { userData, recommendations, summary } = useAppSelector(state => ({
    userData: getUserData(state),
    recommendations: getRecommendationsData(state),
    summary: getTransactionsSummary(state),
  }));

  const goal = userData?.monthly_budget * 12;

  const getUrlMonthlyParams = () => {
    let path = '';
    if (isKeyInObject(recommendations[0].query_str, tabIssues)) {
      path = `${path}?issue=${recommendations[0].query_str}`;
      if (userData?.monthly_budget) {
        path = `${path}&amount=${userData?.monthly_budget?.toString()}`;
      }
    }
    return path;
  };

  const handleClick = () => {
    logFirebaseEvent('donate_intent_profile', {
      timestamp: new Date().toString(),
      user_id: userData.id,
      // tag_id: recommendations?.length > 0 ? recommendations[0].query_str : '',
      tag_id: recommendations?.length > 0 ? 'stop2end' : '',
      recommended_amount: userData?.monthly_budget || 0,
    });
  };

  const getRecommendedDonationAmount = () => {
    if (!userData || !userData.monthly_budget || Number(userData.monthly_budget) === 0) {
      return DEFAULT_RECOMMENDATION_BUDGET;
    }
    return Number(userData.monthly_budget);
  };

  return (
    <>
      <RowContainer>
        <LeftContent
          $width={'60%'}
          $withBorder={!getIsFeatureOn(IMPACT_REPORT)}
          $needsPadding={getIsFeatureOn(IMPACT_REPORT)}
        >
          <Row>
            <Column $width={'50%'}>
              <BigText>
                {formatNumberAsCurrency(summary?.total_amount_donated_ytd || 0, {
                  trailingZeroDisplay: 'auto',
                })}
              </BigText>
              <SmallText color={theme.shadows.black(0.5)}>Donated this cycle</SmallText>
            </Column>
            <Column>
              <BigText>{summary?.total_candidates_ytd || '-'}</BigText>
              <SmallText color={theme.shadows.black(0.5)}>Candidates supported</SmallText>
            </Column>
          </Row>
          <Spacing $size={32} />
          {!!goal && (
            <CustomSlider
              fullWidth
              options={[
                { key: 0, label: '0%' },
                { key: goal / 4, label: '25%' },
                { key: goal / 2, label: '50%' },
                { key: (3 * goal) / 4, label: '75%' },
                { key: goal, label: '100%' },
              ]}
              value={summary?.total_amount_donated_ytd || 0}
              disabled
            />
          )}
        </LeftContent>
        {getIsFeatureOn(IMPACT_REPORT) ? (
          <RightContent $width="40%" $withBorder={!getIsFeatureOn(IMPACT_REPORT)}>
            <LottieOrbs>
              <Column>
                <Eyebrow $color={theme.colors.white}>NEW!</Eyebrow>
                <Spacing $size={10} />
                <H4 $color={theme.colors.white}>Your 2024 Oath Impact Report</H4>
                <BodySmall $color={theme.colors.white}>
                  Take a look at your year in review sed do eiusmod tempor incididunt ut labore et
                  dolore magna.
                </BodySmall>
                <Spacing $size={18} />
                <Column $justifyItems="center">
                  <ButtonBrand onClick={viewImpactReport} $secondary $width="100%">
                    View Now
                  </ButtonBrand>
                  <Spacing $size={8} />
                  <Link $color={theme.colors.white}>View Oath’s Community Report</Link>
                </Column>
              </Column>
            </LottieOrbs>
          </RightContent>
        ) : (
          <RightContent $width="40%" $withBorder>
            <SmallText color={theme.colors.inkBlue}>Today’s Recommendation:</SmallText>
            {recommendations?.length > 0 && (
              <>
                <Spacing $size={10} />
                <H4 $color={theme.colors.oathBlue}>
                  {formatNumberAsCurrency(getRecommendedDonationAmount(), {
                    trailingZeroDisplay: 'auto',
                  })}{' '}
                  {/* to {recommendations[0].value} */}
                  to STOP2End
                </H4>
                <Spacing $size={4} />
                <SmallText color={theme.colors.inkBlue}>
                  {/* These candidates are running in positions that will oversee election results in
                  key states like AZ, MI, and PA. */}
                  Donate any amount and we'll auto-unsubscribe you from 20+ email lists!
                </SmallText>
                <Spacing $size={12} />
                <Row $justifyContent="space-between">
                  <ButtonBrand
                    // href={`${ENV_CONFIG().SITE_URL}?p=${recommendations[0].query_str}${ref_code}`}
                    href={`${ENV_CONFIG().SITE_URL}?p=stop2end${ref_code}`}
                    target="_blank"
                    onClick={handleClick}
                    $secondary
                    $hasBorder
                    $width="100%"
                  >
                    DONATE ONE TIME
                  </ButtonBrand>
                  {/* <Spacing $isVertical $size={16} />
                  <ButtonBrand
                    href={`/monthly${getUrlMonthlyParams()}`}
                    target="_blank"
                    onClick={handleClick}
                    $width="100%"
                  >
                    DONATE MONTHLY
                  </ButtonBrand> */}
                </Row>
                <Spacing $size={16} />
                <Link to="/recommendations" style={{ textAlign: 'center', color: 'inherit' }}>
                  <BodySmall $color={theme.colors.shaft}>See more recommendations</BodySmall>
                </Link>
              </>
            )}
          </RightContent>
        )}
      </RowContainer>
      <Spacing $size={32} />
    </>
  );
};
