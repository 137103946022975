const components = {
  'countdown-close-the-gap': {
    start_date: '2024-09-26T00:00:00',
    end_date: '2024-10-30T00:00:00',
    enabled: true,
  },
  'countdown-days-until-election': {
    start_date: '2024-10-31T00:00:00',
    end_date: '2024-11-05T00:00:00',
    enabled: true,
  },
};

export const siteConfiguration = (id: string) => {
  const now = new Date();
  const component = components[id];

  if (!component) {
    return { isComponentVisible: false, isComponentEnabled: false };
  }

  const { start_date, end_date, enabled } = component;

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const isComponentVisible = now >= startDate && now <= endDate;
  const isComponentEnabled = enabled;

  return { isComponentVisible, isComponentEnabled };
};
