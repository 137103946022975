import { FC, ReactElement } from 'react';
import theme from 'config/theme';
import { Container, BannerText, TextWrapper } from './styled';

export interface IBanner {
  text: ReactElement;
  cta?: () => void;
  background?: string;
  textColor?: string;
}

const Banner: FC<IBanner> = ({ text, cta, background, textColor }) => {
  return (
    <Container $background={background} onClick={cta} role="button" tabIndex={0}>
      <BannerText>
        {Array.from({ length: 50 }, (_, index) => (
          <TextWrapper key={index} $color={textColor || theme.colors.inkBlue}>
            {text}
          </TextWrapper>
        ))}
      </BannerText>
    </Container>
  );
};

export default Banner;
