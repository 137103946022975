import { FC, useEffect, useState } from 'react';
import { animate, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import LottieStory from 'components/LottieElements/Story';
import { formatNumber } from 'helpers/utils';
import { DURATION_1, DURATION_2, FADE_OUT_LEFT, OPACITY_0, OPACITY_1 } from './animations';
import { ISlideProps } from '.';
import {
  BigCounter,
  Container,
  MotionContainer,
  MotionText,
  OuterContainer,
  SquareContainer,
} from './styled';

export const Slide1: FC<ISlideProps> = ({ isPaused, impactReportData }) => {
  const [showFirstLayer, setShowFirstLayer] = useState(true);
  const [showSecondLayer, setShowSecondLayer] = useState(false);
  const [showThirdLayer, setShowThirdLayer] = useState(false);
  const [showLastLayer, setShowLastLayer] = useState(false);

  const amountDonated = useMotionValue(0);
  const amountDonatedCount = useTransform(amountDonated, latest =>
    formatNumber(Math.round(latest))
  );
  const amountWorth = useMotionValue(0);
  const amountWorthCount = useTransform(amountWorth, latest => formatNumber(Math.round(latest)));

  useEffect(() => {
    if (showFirstLayer) {
      setTimeout(() => {
        setShowFirstLayer(false);
        setShowSecondLayer(true);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (showSecondLayer) {
      const controls = animate(amountDonated, parseFloat(impactReportData.donation_amount_total), {
        duration: 3,
      });
      if (isPaused) controls.pause();
    }
  }, [showSecondLayer, isPaused]);

  useEffect(() => {
    if (showThirdLayer) {
      const controls = animate(
        amountWorth,
        parseFloat(impactReportData.donation_amount_total_adjusted),
        {
          duration: 3,
        }
      );
      if (isPaused) controls.pause();
    }
  }, [showThirdLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = amountDonated.on('change', latest => {
      if (Math.round(latest) >= Math.round(parseFloat(impactReportData.donation_amount_total))) {
        setTimeout(() => {
          setShowSecondLayer(false);
          setShowThirdLayer(true);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountDonated]);

  useEffect(() => {
    const unsubscribe = amountWorth.on('change', latest => {
      if (
        Math.round(latest) >=
        Math.round(parseFloat(impactReportData.donation_amount_total_adjusted))
      ) {
        setTimeout(() => {
          setShowThirdLayer(false);
          setShowLastLayer(true);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountWorth]);

  return (
    <>
      {(showFirstLayer || showSecondLayer) && (
        <LottieStory slide="intro" isPaused={isPaused}>
          <AnimatePresence>
            {showFirstLayer && (
              <Container
                key="firstLayer"
                initial={OPACITY_0}
                animate={OPACITY_1}
                exit={FADE_OUT_LEFT}
                transition={DURATION_2}
              >
                <MotionText>Since joining Oath in 2024,</MotionText>
              </Container>
            )}
          </AnimatePresence>
        </LottieStory>
      )}
      {!showFirstLayer && (
        <AnimatePresence>
          <MotionContainer
            key="secondLayerContainer"
            initial={{ ...OPACITY_0, backgroundColor: theme.colors.transparent }}
            animate={{ ...OPACITY_1, backgroundColor: theme.colors.inkBlue }}
            exit={OPACITY_1}
            transition={DURATION_1}
          >
            <AnimatePresence>
              {showSecondLayer && (
                <Container
                  key="secondLayer"
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  exit={OPACITY_1}
                  transition={DURATION_2}
                  $alignContent="space-around"
                >
                  <MotionText exit={OPACITY_0}>You donated</MotionText>
                  <Row>
                    <BigCounter
                      exit={OPACITY_0}
                      transition={{ duration: 0 }}
                      $size={
                        impactReportData.donation_amount_total.toString().length === 5
                          ? 95
                          : impactReportData.donation_amount_total.toString().length > 5
                          ? 80
                          : 120
                      }
                    >
                      $
                    </BigCounter>
                    <BigCounter
                      exit={OPACITY_0}
                      transition={{ duration: 0 }}
                      $size={
                        impactReportData.donation_amount_total.toString().length === 5
                          ? 95
                          : impactReportData.donation_amount_total.toString().length > 5
                          ? 80
                          : 120
                      }
                    >
                      {amountDonatedCount}
                    </BigCounter>
                  </Row>
                  <MotionText exit={OPACITY_0}>this cycle</MotionText>
                </Container>
              )}
              {showThirdLayer && (
                <Container
                  key="secondThirdLayer"
                  initial={OPACITY_1}
                  animate={{ y: -200 }}
                  transition={DURATION_1}
                  $alignContent="space-around"
                >
                  <BigCounter
                    $size={
                      impactReportData.donation_amount_total.toString().length === 5
                        ? 95
                        : impactReportData.donation_amount_total.toString().length > 5
                        ? 80
                        : 120
                    }
                  >
                    ${amountDonatedCount.get()}
                  </BigCounter>
                </Container>
              )}
              {showLastLayer && (
                <>
                  <Container
                    key="secondLastLayer"
                    initial={{ y: '50%', backgroundColor: theme.colors.oathBlue }}
                    animate={{ y: '0%', backgroundColor: theme.colors.oathBlue }}
                    transition={DURATION_2}
                    $alignContent="space-around"
                  />
                  <OuterContainer animate={{ paddingTop: 60 }} transition={DURATION_2}>
                    <SquareContainer>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={
                          impactReportData.donation_amount_total.toString().length === 4
                            ? 100
                            : impactReportData.donation_amount_total.toString().length === 5
                            ? 85
                            : impactReportData.donation_amount_total.toString().length === 6
                            ? 67
                            : impactReportData.donation_amount_total.toString().length > 6
                            ? 60
                            : 115
                        }
                      >
                        ${amountDonatedCount.get()}
                      </BigCounter>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={80}
                      >
                        =
                      </BigCounter>
                      <BigCounter
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={DURATION_2}
                        $size={
                          impactReportData.donation_amount_total_adjusted.toString().length === 5
                            ? 85
                            : impactReportData.donation_amount_total_adjusted.toString().length > 5
                            ? 67
                            : impactReportData.donation_amount_total_adjusted.toString().length > 6
                            ? 60
                            : 95
                        }
                      >
                        ${amountWorthCount.get()}
                      </BigCounter>
                    </SquareContainer>
                  </OuterContainer>
                </>
              )}
            </AnimatePresence>
          </MotionContainer>
        </AnimatePresence>
      )}
      {showThirdLayer && (
        <AnimatePresence>
          <MotionContainer
            key="ThirdLayerContainer"
            initial={{ y: '100%', backgroundColor: theme.colors.transparent }}
            animate={{ y: '50%', backgroundColor: theme.colors.oathBlue }}
            transition={DURATION_1}
          >
            {showThirdLayer && (
              <>
                <MotionText
                  initial={{
                    ...OPACITY_0,
                    position: 'absolute',
                    top: -16,
                    width: '100%',
                    textAlign: 'center',
                  }}
                  animate={OPACITY_1}
                  exit={OPACITY_0}
                  transition={DURATION_1}
                >
                  Your ${amountDonatedCount.get()} donation was worth
                </MotionText>
                <Container
                  key="thirdLayer"
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  exit={OPACITY_1}
                  transition={DURATION_1}
                  $alignContent="space-around"
                >
                  <Row>
                    <BigCounter
                      exit={{ ...OPACITY_1, y: -window.innerHeight / 3 }}
                      transition={DURATION_1}
                      $size={
                        impactReportData.donation_amount_total_adjusted.toString().length === 5
                          ? 95
                          : impactReportData.donation_amount_total_adjusted.toString().length > 5
                          ? 80
                          : 115
                      }
                    >
                      $
                    </BigCounter>
                    <BigCounter
                      exit={{ ...OPACITY_1, y: -window.innerHeight / 3 }}
                      transition={DURATION_1}
                      $size={
                        impactReportData.donation_amount_total_adjusted.toString().length === 5
                          ? 95
                          : impactReportData.donation_amount_total_adjusted.toString().length > 5
                          ? 80
                          : 115
                      }
                    >
                      {amountWorthCount}
                    </BigCounter>
                  </Row>
                  <BigCounter
                    exit={OPACITY_0}
                    $size={
                      impactReportData.donation_amount_total_adjusted.toString().length === 5
                        ? 95
                        : impactReportData.donation_amount_total_adjusted.toString().length > 5
                        ? 80
                        : 115
                    }
                  >
                    ${amountDonatedCount.get()}
                  </BigCounter>
                </Container>
              </>
            )}
          </MotionContainer>
        </AnimatePresence>
      )}
      <AnimatePresence>
        {showLastLayer && (
          <Container
            key="lastLayer"
            initial={{ ...OPACITY_1, y: '50%', backgroundColor: theme.colors.oathBlue }}
            animate={OPACITY_1}
            transition={DURATION_1}
            $alignContent="flex-start"
            style={{ paddingTop: 60 }}
          >
            <MotionText transition={DURATION_1}>
              My ${amountDonatedCount.get()} donation was worth ${amountWorthCount.get()} with Oath!
            </MotionText>
            <Spacing $size={16} />
            <MotionText $size={16}>
              That means my dollars went {impactReportData.donation_amount_multiplier}x further!
            </MotionText>
            <Spacing $size={16} />
            <MotionText>(Add why it’s worth)</MotionText>
          </Container>
        )}
      </AnimatePresence>
    </>
  );
};
