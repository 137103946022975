import { combineReducers } from 'redux';
import democracyEngineReducer from './democracyengine/reducers';
import faqsReducer from './faqs/reducers';
import galleryReducer from './gallery/reducers';
import impactReportReducer from './impactReport/reducers';
import payReducer from './pay/reducers';
import paymentsReducer from './payments/reducers';
import questionsReducer from './questions/reducers';
import recipientsReducer from './recipients/reducers';
import recommendationsReducer from './recommendations/reducers';
import setsReducer from './sets/reducers';
import subscriptionsReducer from './subscriptions/reducers';
import tagsReducer from './tags/reducers';
import transactionsReducer from './transactions/reducers';
import userReducer from './user/reducers';

export default combineReducers({
  democracyEngine: democracyEngineReducer,
  faqs: faqsReducer,
  gallery: galleryReducer,
  impactReport: impactReportReducer,
  pay: payReducer,
  payments: paymentsReducer,
  questions: questionsReducer,
  recipients: recipientsReducer,
  recommendations: recommendationsReducer,
  sets: setsReducer,
  subscriptions: subscriptionsReducer,
  tags: tagsReducer,
  transactions: transactionsReducer,
  user: userReducer,
});
