import styled from 'styled-components/macro';

export const OrbsContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 38%;
  height: -webkit-fill-available;
  right: 0;
  top: 36px;

  @media (max-width: 1000px) {
    position: relative;
    width: 100%;
    max-height: 200px;
  }
`;

export const ChildrenContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
`;
