import theme from 'config/theme';
import { FC } from 'react';
import { CounterCharacter, CounterNumber, CounterRow } from './styled';
import { formatNumberAsCurrency } from 'helpers/utils';

interface Props {
  number: string;
}

export const Counter: FC<Props> = ({ number }) => {
  const numberList = formatNumberAsCurrency(Number(number)).split('.')[0].split('');
  return (
    <CounterRow $alignItems="center" $justifyContent="center">
      {numberList.map((num, index) =>
        num === ',' || num === '$' ? (
          <CounterCharacter key={index} $fontType="regular">
            {num}
          </CounterCharacter>
        ) : (
          <CounterNumber key={index}>
            <CounterCharacter $color={theme.colors.oathBlue} $fontType="regular">
              {num}
            </CounterCharacter>
          </CounterNumber>
        )
      )}
    </CounterRow>
  );
};
