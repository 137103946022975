import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { getUrlParams, noop } from 'helpers/utils';

const emailCampaignSubscriptions = [
  // form-based
  'harris-walz',
  'democrats',
  'dccc',
  'dscc',
  'katieporter',
  'bobcasey',
  'adamschiff',
  'elizabethwarren',
  'pramilajayapal',
  'alexpadilla',
  'gavinnewsom',
  'elissaslotkin',
  'angelaalsobrooks',
  'tammybaldwin',
  'ocasiocortez',
  'rubengallego',
  // email-based
  'colinallred',
];

export const getIsPageStop2End = () => {
  const { urlPartner } = getUrlParams();
  return urlPartner === 'stop2end';
};

export const getIsInStop2End = (userEmail: string, userId: string) => {
  return getIsPageStop2End() && !!userId && !!userEmail;
};

export const handleStop2EndDonationIfNeeded = (page: string, userEmail: string, userId: string) => {
  if (page !== 'stop2end' || !getIsInStop2End(userEmail, userId)) {
    return;
  }
  try {
    const campaigns = emailCampaignSubscriptions.join(',');
    const data = {
      email: userEmail,
      campaign: campaigns,
      action: 'unsubscribe',
    };
    datadogLogs.logger.info(
      `Starting unsubscribe using stop2end API with user_id: ${userId} from campaigns: ${campaigns}.`
    );
    axios
      .post('https://stop2end-api.vercel.app/api/unsubscribe', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        datadogLogs.logger.info(
          `Successfully unsubscribed using stop2end API with user_id: ${userId} from campaigns: ${campaigns}. API response data: ${JSON.stringify(
            response.data[userEmail]
          )} `
        );
      })
      .catch(error => {
        datadogLogs.logger.info(
          `Failed to unsubscribe using stop2end API with user_id: ${userId} from campaigns: ${campaigns}. API error data: ${error} `,
          error
        );
      });
  } catch (error) {
    noop();
  }
};
