import { CSSProperties, ReactNode } from 'react';

export interface LottieProps {
  children?: ReactNode;
  slide?: string;
  isPaused?: boolean;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
}

export const defaultOptions = (animationData: any) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});
