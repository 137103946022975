import { Row } from 'components/Row';
import { FC, useEffect, useState } from 'react';
import { ProcessingImageSmallWhite, SubmitPaymentRetry } from '../styled';
import classNames from 'classnames';

interface Props {
  hasError: boolean;
  isVerified: boolean;
  onClick: () => void;
  submitting: boolean;
}

export const RetryPaymentButton: FC<Props> = ({ hasError, isVerified, onClick, submitting }) => {
  const [hadError, setHadError] = useState(false);

  useEffect(() => {
    if (hasError) {
      setHadError(true);
    }
  }, [hasError]);

  if (!hadError || isVerified) {
    return null;
  }

  return (
    <Row $justifyContent="space-around">
      <SubmitPaymentRetry
        className={classNames({ 'animated-background': submitting })}
        type="button"
        disabled={submitting}
        onClick={onClick}
        $submitting={submitting}
      >
        <Row $alignItems="center" $justifyContent="center">
          <ProcessingImageSmallWhite
            src="https://storage.googleapis.com/oath_1/static_content/misc_icons/circle_arrows_white.png"
            alt="Circle formed by two arrows"
          />
          Try Again
        </Row>
      </SubmitPaymentRetry>
    </Row>
  );
};
