import { CSSProperties, FC, useEffect, useState } from 'react';
import Modal from 'react-modal';
import theme from 'config/theme';
import Icon, { IconNames } from 'components/Icon';

const contentStyles: CSSProperties = {
  border: `1px solid ${theme.colors.lightGray}`,
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  left: '0',
  height: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '80%',
  overflow: 'hidden',
  padding: '27px 23px 9px',
  right: '0',
  width: '550px',
};
const fullScreenContentStyles: CSSProperties = {
  background: theme.colors.white,
  height: '100%',
  left: '0',
  overflow: 'scroll',
  padding: 0,
  right: '0',
  top: '0',
  width: '100%',
};
const overlayStyles: CSSProperties = {
  backgroundColor: 'rgba(4, 5, 58, 0.5)',
  zIndex: 15,
};
const closeButtonStyles: CSSProperties = {
  cursor: 'pointer',
  right: '12.5px',
  top: '10px',
  zIndex: 1000,
  position: 'absolute' as any,
};

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  contentLabel: string;
  isFullScreen?: boolean;
  style?: CSSProperties;
  withCustomStyle?: boolean;
  iconColor?: string;
}

export const ModalComponent: FC<Props> = ({
  isOpen,
  closeModal,
  children,
  contentLabel,
  isFullScreen = false,
  style,
  withCustomStyle,
  iconColor,
}) => {
  const getCustomStyles = (isFullScreen?: boolean, style = {} as CSSProperties) => ({
    content: isFullScreen
      ? fullScreenContentStyles
      : {
          ...contentStyles,
          ...(withCustomStyle ? contentCustomStyle : {}),
          ...style,
        },
    overlay: overlayStyles,
  });

  const [contentCustomStyle, setContentCustomStyle] = useState({
    inset: '0',
    borderRadius: '0px',
    maxWidth: '100%',
    width: '100%',
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 490 || window.innerHeight <= 700) {
        setContentCustomStyle({
          inset: '0',
          borderRadius: '0px',
          maxWidth: '100%',
          width: '100%',
        });
      } else {
        setContentCustomStyle({
          inset: '40px 0',
          borderRadius: '20px',
          maxWidth: '80%',
          width: 'fit-content',
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={getCustomStyles(isFullScreen, style)}
      contentLabel={contentLabel}
    >
      {children}
      <Icon
        onClick={closeModal}
        name={IconNames.XClose}
        size={32}
        color={iconColor || theme.colors.inkBlue}
        style={closeButtonStyles}
      />
    </Modal>
  );
};
