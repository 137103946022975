import { FC, useEffect, useState } from 'react';
import Stories from 'react-insta-stories';
import { useAppSelector } from 'helpers/hooks';
import { getImpactReportData } from 'selectors/impactReport';
import { ImpactReportData } from 'store/impactReport/types';
import { Intro } from './intro';
import { Slide1 } from './slide1';
import { Slide2 } from './slide2';
import { Slide3 } from './slide3';
import { Slide4 } from './slide4';

interface Props {
  isSlideVisible: boolean;
  showSlides: (state: boolean) => void;
}
export interface ISlideProps {
  isPaused: boolean;
  impactReportData: ImpactReportData;
}

export const SlideStories: FC<Props> = ({ isSlideVisible, showSlides }) => {
  const impactReportData = useAppSelector(getImpactReportData);

  useEffect(() => {
    showSlides(isSlideVisible);
  }, [isSlideVisible]);

  const [storiesWidth, setStoriesWidth] = useState(390);
  const [stories, setStories] = useState([
    {
      duration: 3000,
      content: ({ isPaused }) => <Intro isPaused={isPaused} />,
    },
    {
      duration: 24000,
      content: ({ isPaused }) => <Slide1 isPaused={isPaused} impactReportData={impactReportData} />,
    },
    {
      duration: 30000,
      content: ({ isPaused }) => <Slide2 isPaused={isPaused} impactReportData={impactReportData} />,
    },
    {
      duration: 16000,
      content: ({ isPaused }) => <Slide3 isPaused={isPaused} impactReportData={impactReportData} />,
    },
    {
      duration: 18000,
      content: ({ isPaused }) => <Slide4 isPaused={isPaused} impactReportData={impactReportData} />,
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 490) {
        setStoriesWidth(window.innerWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!impactReportData?.issues?.length) {
      setStories(prevStories => prevStories.slice(0, 4));
    } else if (impactReportData?.issues?.length === 1) {
      setStories(prevStories =>
        prevStories.map((story, index) => (index === 3 ? { ...story, duration: 8000 } : story))
      );
    }
  }, [impactReportData]);

  return (
    <>
      {isSlideVisible ? (
        <Stories
          stories={stories}
          height="100%"
          width={storiesWidth}
          onAllStoriesEnd={() => showSlides(false)}
        />
      ) : null}
    </>
  );
};
