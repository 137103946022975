// Active feature flags
export const ADMINS_ONLY = 'admin';
export const IMPACT_REPORT = 'impact_report';
export const BANNER = 'banner';

export const getIsFeatureOn = (feature: string) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  return searchParams.has(feature);
};
